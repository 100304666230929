.container{
    width: 80% !important;
}
#u_content_html_4 img {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-right: 10px;
}

ul li{ display: inline-block !important; width: 25%;}