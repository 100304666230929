@font-face {
  font-family: "Avenir Next LT W02 Regular";
  src: url("../fonts/be9a43357e0fe07642191e4b32995385.eot");
  /* IE9*/
  src: url("../fonts/be9a43357e0fe07642191e4b32995385.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../fonts/be9a43357e0fe07642191e4b32995385.woff2") format("woff2"),
    /* chrome、firefox */
    url("../fonts/be9a43357e0fe07642191e4b32995385.woff") format("woff"),
    /* chrome、firefox */
    url("../fonts/be9a43357e0fe07642191e4b32995385.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/be9a43357e0fe07642191e4b32995385.svg#Avenir Next LT W02 Regular") format("svg");
  /* iOS 4.1- */
}

@import "./icons.scss";

// Dark Theme
//@import "./bootstrap-dark.scss";
//@import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}

svg>rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      >* {
        >* {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

/*  tui charts**/
.tui-chart .tui-chart-chartExportMenu-area {
  z-index: 1 !important;
}

.mt20 {
  margin-top: 1.875rem;
}

.mb20 {
  margin-bottom: 1.875rem;
}

.form-title {
  margin-top: 0px;
  margin-bottom: 20px;
}

.mr5 {
  margin-right: 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #dbdd0b;
  border-color: #dbdd0b;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
  padding-top: 10px;
}

.mr-1n {
  margin-right: .25rem !important;
}

.mb15 {
  margin-bottom: 15px;
}

.rowwarning {
  background-color: #fbcfcf;
}

.navbar-brand-box {
  padding: 0 1.6rem !important;
}

body[data-sidebar="dark"] .vertical-menu {
  background: #f2c80f;
}

body[data-sidebar="dark"] .navbar-brand-box {
  background: #fff;
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
  color: #000;
  font-family: Britannic Bold;
  font-size: 18px;
}

.nav-tabs {
  background: #e8e8e8;
}

.nav-tabs>li>a {
  color: #342c2c;
  font-size: 11px !important;
}

.prdetail-tab>li>a {
  font-size: 13px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #f8f9c0;
  border-color: #f8f9c0;
  border-radius: 0px;

}

#sidebar-menu ul li ul.sub-menu li a {
  font-size: 15px !important;
  color: #333 !important;
}

.btn-detail {
  background: #01b8aa;
  border-color: #01b8aa;
  color: #fff;
}

.btn-second {
  background: #3599b8;
  border-color: #3599b8;
  color: #fff;
}

.btn-detail:hover {
  color: #fff;
}

.btn-second:hover {
  color: #fff;
}

textarea.form-control {
  min-height: 150px;
}

.project-list-table thead th {
  background: rgb(160, 194, 216);
  color: #000;
}

.mini-stats-wid h4 {
  color: #0574a8;
  font-size: 25px;
}

.nav-tabs-custom {
  background: none !important;
}

.btn-green {
  background: #90b389;
  border-color: #90b389;
  color: #fff;
}

.btn-red {
  background: #a13545;
  border-color: #a13545;
  color: #fff;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background: #fff;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul ul {
  background-color: #f2c80f;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background-color: #f2c80f;
}

.emailtemp1 {
  background-image: url('../images/email-1.png');
  width: 150px;
  height: 117px;
}

.emailtemp2 {
  background-image: url('../images/email-2.png');
  width: 150px;
  height: 117px;
}


.chekbuton input[type="radio"] {
  display: none;
}

.chekbuton input[type="radio"] label {
  display: inline-block;
  margin: -2px;
  margin-bottom: -2px;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 30px;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  width: 100%;
  border: 1px solid #ccc;
  font-weight: 400;
}

.chekbuton input[type="radio"]:checked+label {

  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
  background-color: #000;
  color: #fff;
  border: 2px solid #49b933;
}

.mini-stats-wid .fw-medium {
  font-weight: bold;
}

.link-bg {
  background: #9a9393;
  padding: 10px;
  font-size: 15px;
  margin-bottom: 0px;
  text-align: center;
}

.link-bg a {
  color: #fff !important;
}

.condTab .nav-link {
  padding: 0.5rem 0.7rem !important;
}

.dark-text {
  color: #A66999;
}

.leadTab .nav-link {
  padding: 0.5rem 0.7rem !important;
  text-align: center;
}

.condTab .nav-link {
  text-align: center;
}

.primg {
  margin-right: 10px;
}

.mini-stats-wid {
  background-color: #f8f8fb !important;
  box-shadow: none !important;
}

.mini-stats-wid p {
  color: #342c2c !important;
  font-size: 14px !important;
}

.mini-stats-wid h4 {
  color: #342c2c !important;
}

.bg-primary.bg-soft {
  background-color: #f2c80f !important;
  padding-bottom: 42px;
}

.bg-soft h5,
.bg-soft p {
  color: #fff !important;
}

.piediv {
  height: 30px;
}

.actdiv {
  height: 20px;
}

.bardiv {
  height: 30px;
}

.pieleaddiv {
  height: 22px;
}

.bg-soft h5,
.bg-soft p {
  color: #342c2c !important;
}

.avatar-title {
  background-color: #f2c80f !important;
}

.btn-danger,
.btn-primary {
  background-color: #E7DFD2 !important;
  border-color: #E7DFD2 !important;
  color: #000 !important;
  border: 1px solid;
}

.btn-delete {
  //background-color:#a13545 !important;
  //border-color: #a13545 !important;
  color: #a13545 !important;
  //border: 1px solid;
  font-size: 30px;
}

.btn-add {
  color: #fff !important;
  background-color: #bd1d19 !important;
  border-color: #bd1d19 !important;
  font-size: 16px;
  font-weight: bold;
}

.btn-bulk {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
  font-size: 16px;
  font-weight: bold;
}
.br-radius{ border-radius: 10px !important;}
@media screen and (min-width:1920px) and (max-width:2100px) {

  .actdiv {
    height: 125px;
  }

  .apexdiv {
    height: 79px;
  }

  .pieleaddiv {
    height: 0px;
  }

  .bardiv {
    height: 82px;
  }
}


.main-timeline {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #939597;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: #939597;
  border: 5px solid #F5DF4D;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

  /* Place the timelime to the left */
  .main-timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 18px;
  }

  .left::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

.mt-27 {
  margin-top: 27px;
}

.mail-box .nav-tabs>li>a {
  font-size: 14px !important;
}






.btnCheckGroup .form-control .form-check {
  background-color: #f1b44c;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  padding-left: 50px;
}


.btnCheckGroup .form-check-input:checked {
  background-color: #000;
  border-color: #000;
}

.btnCheckGroup label {
  background-color: #f1b44c;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  padding-left: 50px;
}

.btnCheckGroup label {
  margin-bottom: 0px;
  color: #000;
  margin-right: 20px;
}

.btnCheckGroup input {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.verti-timeline .eventlist .event-timeline-dot {
  top: 8px !important;
}

.eventlist {
  border-bottom: 1px dotted #ccc;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.offcanvas-header {
  background-color: #f1b44c;
  color: #000;
}

.projectTab {
  background-color: #e8e8e8;
}

.projectTab .nav-item {
  padding: 0px !important;

}

.projectTab>li>a {
  font-size: 18px !important;
  color: rgb(51, 71, 91);
  font-weight: 500;
  text-transform:capitalize;
  font-family: 'Avenir Next LT W02 Regular';
}

.projectTab .nav-item .active {
  color: #000;
  font-weight: bold;
}

.projectTab .nav-link:hover,
.projectTab .nav-link:focus {
  border-color: transparent;
  color: #000;
  background-color: #fff;
}

.projectTab .nav-link.active,
.projectTab .nav-item.show .nav-link {
  border-color: transparent;
  color: #000;
  background-color: #fff;
}

.activitytab {
  background-color: rgb(255, 255, 255);
}

.activitytab .nav-item {
  // padding: 1rem 1rem !important;

}

.activitytab>li>a {
  font-size: 18px !important;
  color: rgb(51, 71, 91);
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Avenir Next LT W02 Regular';
}

.activitytab .nav-link.active,
.activitytab .nav-item.show .nav-link {
  color: #000;
  font-weight: bold;
  background: transparent;
  border-color: transparent;
}

.activitytab .nav-link:hover,
.activitytab .nav-link:focus {
  border-color: transparent;
  color: #000;
}

.tasktab {
  background-color: rgb(160, 194, 216);
}

.tasktab .nav-item {
  // padding: 1rem 1rem !important;

}

.tasktab>li>a {
  font-size: 18px !important;
  color: rgb(51, 71, 91);
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Avenir Next LT W02 Regular';
}

.tasktab .nav-link.active,
.tasktab .nav-item.show .nav-link {
  color: #000;
  font-weight: bold;
  background: transparent;
  border-color: transparent;
}

.tasktab .nav-link:hover,
.tasktab .nav-link:focus {
  border-color: transparent;
  color: #000;
}

.form-label {
  font-size: 16px !important;
  color: rgb(51, 71, 91);
  font-weight: 500;

  font-family: 'Avenir Next LT W02 Regular';
}

.page-title-box h4,
.page-title-box .h4 {
  font-size: 20px !important;
  font-family: 'Avenir Next LT W02 Regular';
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
  font-family: 'Avenir Next LT W02 Regular';
}

body {
  font-family: 'Avenir Next LT W02 Regular' !important;
}

.verti-timeline .event-list .event-timeline-dot {
  z-index: 1;
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.Demo__clear-button,
.Demo__clear-button:active,
.Demo__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 59%;
  transform: translateY(-59%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.Demo__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}

.Demo__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.Demo__suggestion-item--active {
  background-color: #fafafa;
}

.Demo__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.square-switch {
  float: right;
}

.square-switch-left {
  float: left;
  margin: 5px 12px 0 0;

}




#topNavigation {
  height: 295px;
  background-color: #53575A;
}

.d-flex {
  display: flex !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

main {
  margin-top: 80px;
}

.homes {
  top: 0%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.95);
  transform: translate(-10%, 0%);
  position: absolute;
  bottom: 0;
}

.homes p {
  font-size: 35px;
  color: #53575A;
  font-weight: 400;
  line-height: 1.4;
}

.page-title-box h4 {
  font-size: 25px !important;
}

.mini-stats-wid p {
  font-size: 16px !important;
}

.projectTab li a {
  font-size: 16px !important;
}

.table th,
.table td {
  font-size: 14px !important;
}


.aquire-list .text-light {
  min-height: 218px;
}

.aquire-list .text-light a {
  color: #fff !important;
}

.aquire-list .card-title {
  text-align: center;
}

.aquire-list .card-text {
  text-align: center;
  font-size: 18px;
}

.newfrmSelection label {
  background: #fff !important;
  border: 1px solid #0574a8 !important;
  height: 250px;
  width: 250px;
}

.img-text {
  padding: 40px;
}

.email-leftbar {
  width: 100% !important;
}

.email-leftbar .mail-list .nav-tabs>li>a {
  font-size: 13px !important;
}

.btn-edit {
  font-size: 18px;
  color: #000;
}

.btn-sche {
  font-size: 23px;
  color: #49b933;
  margin-left: 10px;
}

.btn-del {
  font-size: 18px;
  color: #ff0000;
  margin-left: 10px;
}

.goal {
  color: #49b933;
}

.card-title {
  font-size: 19px !important;
}

.bdLeft a {
  color: #74788d !important;
}

.btn-view {
  font-size: 18px;
  color: #000;
}

.table-bordered {
  border-left: 5px solid #608484 !important;
}

.noleft {
  border-left: none !important;
}

.btn-delbtn {
  font-size: 25px;
  color: #999;
  background: none;
  border: none;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9));

  background: -webkit-radial-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  z-index: 9999;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(64, 200, 244, 0.75) 1.5em 0 0 0, rgba(64, 200, 244, 0.75) 1.1em 1.1em 0 0, rgba(64, 200, 244, 0.75) 0 1.5em 0 0, rgba(64, 200, 244, 0.75) -1.1em 1.1em 0 0, rgba(64, 200, 244, 0.75) -1.5em 0 0 0, rgba(64, 200, 244, 0.75) -1.1em -1.1em 0 0, rgba(64, 200, 244, 0.75) 0 -1.5em 0 0, rgba(64, 200, 244, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(64, 200, 244, 0.75) 1.5em 0 0 0, rgba(64, 200, 244, 0.75) 1.1em 1.1em 0 0, rgba(64, 200, 244, 0.75) 0 1.5em 0 0, rgba(64, 200, 244, 0.75) -1.1em 1.1em 0 0, rgba(64, 200, 244, 0.75) -1.5em 0 0 0, rgba(64, 200, 244, 0.75) -1.1em -1.1em 0 0, rgba(64, 200, 244, 0.75) 0 -1.5em 0 0, rgba(64, 200, 244, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.leftdata {
  float: left !important;
  margin-top: 2px !important;
}

.templates {
  border: 1px solid #01b8aa;
  float: left;
}

.leftData {
  float: left;
  margin-left: 10px;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.rightData {
  float: right;
  margin-right: 10px;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.toppart {
  background: #fff;
  padding: 12px 0px 0px 12px;
  border-bottom: 5px solid #000;
}

.topTab,
.otherTab {
  background: #fff;
}

.topTab {
  border-bottom: 1px solid #ced4da;
}

.otherTab {
  justify-content: center;
  border-bottom: none;
}

.otherTab li a {
  font-size: 16px !important;
}

.otherTab>li>a {

  color: #33475b;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Avenir Next LT W02 Regular';
}

.otherTab .nav-link.active {
  background-color: transparent !important;
  border-color: transparent !important;
  font-weight: bold;
}

.toppart button {
  margin-bottom: 10px;
}

.btn-d {
  font-size: 24px;
  color: #000;
}

.btn-u {
  font-size: 24px;
  color: #ff0000
}

.nav-tabs-custom .nav-link.active {
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0px;
  font-weight: bold;
  color: #000 !important;
}


.btn-action {
  background-color: transparent;
  border-color: #000;
  color: #ffffff !important;
}



.btn-action:hover {
  background-color: rgba(0, 0, 0, .6);
  border-color: #000;
  color: #fff !important;
}


.app-search .form-control {
  border-radius: 0px !important;
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
}

.setting-menu {
  background-color: #fff;
}

.setting-menu ul li a {
  font-size: 16px !important;
}

.setting-menu ul li a:hover {
  color: #000 !important;
  font-weight: bold !important;
}

.setting-menu ul li a:hover i {
  color: #000 !important;
  font-weight: bold !important;
}

.mm-data a {
  color: #000 !important;
}

.setting-menu .mm-active a.active {
  color: #000 !important;
  font-weight: bold !important;
}

.setting-menu .mm-active a.active i {
  color: #000 !important;
  font-weight: bold !important;
}

.tempData16 {
  background: url(../images/referral-bg.jpg);
}

.tempData17 {
  background: url(../images/review-bg.jpg);
}

.tempData20 {
  background: url(../images/landing-page-bg.jpg);
}

.tempData21 {
  background: url(../images/survey-bg.jpg);
}

.tempData22 {
  background: url(../images/worksheet-bg.jpg);
}

.tempData {
  background-size: 100% auto;
  min-height: 257px !important;
  background-repeat: no-repeat;
}

.tempData .card-body {
  padding-top: 120px !important;
  text-align: center;
}

.tempData .card-body .card-title,
.tempData .card-body .card-text {
  color: #fff !important;
}

.tempLink {
  color: #fff !important;
}

.previewHtml img {
  max-width: 100% !important;
}

.pper {
  padding: 0.67rem 0.75rem !important;
}

.qbg {
  background-color: #eff2f7 !important;
  padding: 10px;
}

.gjs-one-bg {
  background-color: #ffffff !important;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: #060fc5 !important
}

.gjs-two-color {
  color: #000 !important;
}

.dollarfn {
  padding: 0.69rem 0.75rem !important;
}

.rl-group-btn .btn:hover {
  background-color: #dbdd0b !important;
  color: #000 !important;
}

.rl-group-btn .btn.active {
  background-color: #dbdd0b !important;
  color: #000 !important;
}

.rl-group-btn .btn i {
  font-size: 18px !important;
}

.rl-group-btn .btn a {
  color: #000 !important;
}

.rl-group-btn a{
  margin-right: 3px;
}

.icon-upload {
  font-size: 20px;
  vertical-align: middle;
  color: #f82f00 !important;
}

.icon-download {
  font-size: 20px;
  vertical-align: middle;
  color: #0a6204 !important;
}

.grid-icon {
  font-size: 20px;
  //vertical-align: middle;

}





.landing-page-footer {
  background-color: #000;
  color: #000 !important;
}

.actTabs {
  background-color: #fff !important;
  border-bottom: none !important;
}

.actTabs .nav-item {
  border-right: 1px solid #eee !important
}

.whitetab {
  background-color: #fff !important;
  border-bottom: none !important;
}

.email-temp-header {
  background-color: #f2c80f !important;
  color: #000;
  font-size: 18px;
}

.btn-action {
  background-color: #030303;
  border-color: #080808;
  color: #000;
}

.btn-action:hover {
  background-color: #E7DFD2;
  border-color: transparent;
  color: #000 !important;
}

.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #000 !important;
  background-color: transparent;
  ;
  border-color: transparent;
  ;
  box-shadow: 0 0 0 0.15rem rgba(137, 140, 158, 0.5);
}

.card-lead-aquire {
  text-align: center;
}

.card-lead-aquire h5.card-header {
  font-size: 25px;
  font-weight: bold !important;
}

.btn-lead-frm {
  background-color: #ffde59;
  border-color: #ffde59;
  color: #000;
}

.card-lead-aquire .card-title {
  font-size: 14px !important;
  margin-bottom: 15px;
}

.card-lead-aquire .card-image {
  margin-bottom: 15px;
}

.toHeadingRow {
  text-align: center;
}

.toHeadingRow .topHeading h4 {
  font-weight: bold;
  font-size: 14px;
}

.border-rgt {
  border-right: 1px solid #ccc;
}

.border-lft {
  border-left: 1px solid #ccc;
}

.no-border td {
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}

.topHeading {
  // border-top: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

.topHeading h4 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.border-btm {
  border-bottom: 1px solid #ccc;
}

.tb-content .no-border {
  border-bottom: 1px solid #ccc;
}

.tb-content .no-border:last-child {
  border-bottom: none !important
}

.btn-camp {
  background-color: #b4c0d5;
  border-color: #b4c0d5;
  font-size: 16px;
  font-weight: bold;
}

.btn-camp:hover {
  background-color: #d8e0e6;
  border-color: #d8e0e6;
}

.btn-goback {
  border-color: #b4c0d5;
  background-color: transparent;
  font-size: 16px;
  font-weight: bold;
}

.delbtn{ position: absolute; top: 5px; right: 0px; background-color: #fff; width: 40px; height: 40px; border-radius: 50%;}

.btn-gdelete {
  color: #a13545 !important;
  font-size: 20px;
}

.btn-gdelete i{
  margin-left: 8px;
  line-height: 40px;
}

body[data-sidebar="dark"].vertical-collpsed{ min-height: auto !important;}

tr.editRow a{
  font-weight: bold;
}

.label-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 15px ;
}
.label-text{
  font-size: 12px !important;
}
.custom-card{
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0,0,0, 0.3);
}

.t-button-active{
  background-color: #636260 !important;
  color: white !important;
  // border:4px solid black;
  // box-shadow: 5px 5px 1px darkblue;
  // color: #fff;
  //   background-color: #485ec4;
  //   border-color: #4458b8;
  //   box-shadow: 0 0 0 0.15rem rgba(111, 132, 234, 0.5);

}

.offcanvas-end{
  width: 500px !important;
}

.offcanvas-heading{
color : #0047ab;
text-transform: uppercase;
font-size: 20px;
}

.offcanvas-subheading{
  
}

.offcanvas-info{
color : #800020;
  
}
.offcanvas-chk-box{
  width:17px;
  height: 17px;
}

.text-blue{
  color:#0047ab !important;
}

.tab-heading{
  font-weight: bold;
}


// project page css start

.project-page-content{
  padding-top: 70px;
  padding-left: 0px;
  padding-right: 0px;
}
.project-link span:hover{
  color:#0047ab !important;
}

.project-link span{
  color:#495057 !important;
  font-weight: bold;
}

.project-link.active > span {
  color:#0047ab !important;
  font-weight: bold !important;

}

.project-link.has-arrow{&:after{ display: none;}
  display: none;
}

.setting-menu{
  background-color: transparent;
}

.project-bar .simplebar-content-wrapper{
  border-right: 1px solid #e8e8e8;
  background-color: white;
}

.project-header{
  border-bottom: 1px solid #e8e8e8;
  // padding-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 0px 3px 0px 10px;
  background-color: white;
}

.project-header .page-title-box{
  border-right: 1px solid #e8e8e8;
}

.project-header .col-sm-6 h2,h4{
  padding-top: 20px;
  font-weight: bold;
}

.project-header .col-sm-4 h2{
  color: #800020;
  text-transform: uppercase;
  margin-top: 20px ;
  font-weight: bold;
}


.projectNav{
  background-color: white !important;
  border-bottom: 1px solid #e8e8e8;
  // border-top: 3px solid #e8e8e8;
  // justify-content: space-evenly;
}
.projectNav .active span{
  color:#800020;
  // text-decoration: underline;
}

.nav-link.active{   //ned to fix
  border:none;
}
.project-page-content .card{
  margin-bottom: 0px;
  margin-right: 0px;
  border-bottom: 1px solid #e8e8e8;
}
.project-count-card{
  border-left: 1px solid #e8e8e8;
}

// project page css end
.project-page-content .col-lg-12,.col-sm-3,.col-md-12{
padding-right: 0px;

}


.accordion-button{
  font-weight: bold;
}



.links-tab h6{
  font-weight: bold;
}


.sub-menu .project-link span{
  font-weight: 200;
  font-size: 13px;
}

.sub-menu .project-link span .nav-icon{
 width:20px;
 margin-right: 20px;
}

.sub-menu .project-link span i{
 font-size: 1rem !important;
}


//client css

.project-page-content .col-xl-9, .col-sm-9{
  padding-right: 0px;
  padding-left: 0px;
  border-left: 1px solid #e8e8e8;
  }

  .popup-nav{
    background-color: black !important;
  }

  .popup-nav span{
    color:white;
    padding: 0px ;
    // text-decoration: underline;
  }

  .popup-nav .nav-link:hover{
    background-color: rgba(255, 239, 239, 0.308) !important;
  }
  .popup-nav .nav-link:active{
    background-color: rgba(255, 239, 239, 0.308) !important;
  }

  .b-list-table {
    border: 1px  solid #e8e8e8;
    border-bottom: none;
    margin-right: 12px;
    border-left: 5px solid #608484 !important;
  }

  .contact-detail-nav{
    border: 1px  solid #e8e8e8;
    margin-left: -41px;
    margin-right: -41px;
  }

 .main-menu #sidebar-menu ul li a.active{
    // color:red !important;
    background-color: #c1a900 !important;
  }

  .activity-link-img{
    max-width : 150px;
    border : 1px solid #d3d3d3;
    padding : 15px;
  }

  .activity-link-sub-row{
    margin: auto;
    max-width: 200px;
  }


  .activity-link-subimg{
    min-width:30px;
  }
  
  .accordion-button::after {
    background-image: none !important;
  }

.view-leads{

}

.setup-btn{
  background-color: #f5f93d !important;
}

.main-menu li .mm-active{
  background-color: #c1a900 !important;
}
.edit-btn{
  background-color: #0047ab !important;
  color: white !important;
}


.overlay-email {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9; 
  z-index: 9999; /* Ensure it's above other elements */
  overflow: auto;
}



// .overlay-content-email {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   color: white;
//   text-align: center;
//   font-size: 24px;
// }

.data-table > * > * > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.p-paginator-icon{
  padding: 0px !important;
  margin: 2px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ecfeff !important;
  border-color: #ecfeff !important;
  color: #0e7490 !important;
}

.p-dropdown-panel{
  background: #ecfeff !important;
}

.p-paginator-bottom{
  justify-content: flex-end !important;
}

.danger {
  color :white !important;
  background-color: red  !important;
}

.link-page h5 span{ background-color:#ffc5f4; padding: 10px; border-radius: 10px; font-weight: bold; margin-right: 30px;}

.open-bg{ background-color: #f8e6e6 !important; padding: 10px; margin-top: 40px; margin-bottom: 40px;}

.open-bg h4{ color: #000;}

.open-bg h5{ font-weight: bold; color: #000; font-size: 18px;}

.open-bg ul li{ color: #000; font-size: 16px;}
h5.normal-text{ font-weight: bold; color: #000; font-size: 18px; } 
h5.normal-text a{ margin-left: 30px;}
h6.normal-text{ font-weight: bold; color: #000; font-size: 16px; margin-top: 30px;} 

.btn-yellow{
  background-color: #ffde59;
  border-color: #ffde59;
  color: #000;
  border-radius: 5px;
}

.submittedWorksheet h5{ width: 100%;}
.submittedWorksheet h5 span{ margin-right: 20px;}
.submittedWorksheet h5 i{ font-size: 20px !important;}
.tabContent h5{ color:#556ee6 !important; font-weight: bold !important;}

.autocomplete-dropdown {
  border: 1px solid #d4d4d4;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;

}
.suggestion-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.suggestion-item--active {
  background-color: #007BFF;
  color: #fff;
}
.suggestion-item:last-child {
  border-bottom: none;
}
.headrtop{ overflow: hidden;}
.agentInfo span{ font-size: 16px !important; font-weight: normal !important;}
.text-upper{ text-transform: uppercase !important;}
.newCard{ border-radius: 1rem !important; border: 1px solid #ccc !important;}
.accordion-item{ border:none !important;}
.form-check-input{ width: 2em !important; height: 2em !important;}
.mt-c{ margin-top: 7px !important;}
.mt-r{ margin-left: 5px !important; margin-top: 5px !important;}